<template>
  <div
    class="h-100 d-flex flex-column flex-nowrap justify-content-between align-items-center"
  >
    <form
      id="createForm"
      class="pa-3 mx-3 row elevation-0 overflow-y-auto h-100"
    >
      <div class="col-12 h-75px">
        <v-combobox
          v-model="formData.order_ids"
          label="Orders"
          outlined
          dense
          @change="handleChangeMultiple"
          multiple
          deletable-chips
          clearable
          allow-overflow
          small-chips
        />
      </div>
      <div class="col-12 col-md-6 h-75px">
        <v-autocomplete
          v-model="formData.collection_address_id"
          label="Collection address"
          :items="collection_addresses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="collection_address_idErrors"
          @input="$v.formData.collection_address_id.$touch()"
          @blur="$v.formData.collection_address_id.$touch()"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-autocomplete>
      </div>
      <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.warehouse_id"
          label="Delivery address"
          :items="serverData.warehouses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div>
      <!-- <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.destination_country_id"
          label="Destination country"
          :items="serverData.countries"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="destination_country_idErrors"
          @input="$v.formData.destination_country_id.$touch()"
          @blur="$v.formData.destination_country_id.$touch()"
        ></v-select>
      </div>
      <div class="col-12 col-md-6 h-75px">
        <v-autocomplete
          :loading="isLoading"
          v-model="formData.destination_city_id"
          :search-input.sync="city_search_text"
          label="Destination city"
          item-text="text"
          item-value="index"
          :items="serverData.cities"
          dense
          clearable
          outlined
          cache-items
          :disabled="!formData.destination_country_id"
          placeholder="Enter first two letters of city name"
          :error-messages="destination_city_idErrors"
          @input="$v.formData.destination_city_id.$touch()"
          @blur="$v.formData.destination_city_id.$touch()"
        ></v-autocomplete>
      </div> -->
      <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.vehicle_owner_type_id"
          label="Vehicle owner type"
          :items="serverData.vehicle_owner_types"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="vehicle_owner_type_idErrors"
          @input="$v.formData.vehicle_owner_type_id.$touch()"
          @blur="$v.formData.vehicle_owner_type_id.$touch()"
        ></v-select>
      </div>
      <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.vehicle_tonnage_id"
          label="Vehicle tonnage"
          :items="serverData.tonnages"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="vehicle_tonnage_idErrors"
          @input="$v.formData.vehicle_tonnage_id.$touch()"
          @blur="$v.formData.vehicle_tonnage_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-12 h-75px">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="ma-0 pa-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.collection_date"
              label="Collection date"
              readonly
              clearable
              dense
              outlined
              v-bind="attrs"
              v-on="on"
              prepend-inner-icon="mdi-calendar"
              :error-messages="collection_dateErrors"
              @input="$v.formData.collection_date.$touch()"
              @blur="$v.formData.collection_date.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.collection_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-sm-6 col-12 h-75px">
        <v-select
          v-model="formData.package_type"
          label="Package type"
          item-text="text"
          item-value="index"
          :items="serverData.package_types"
          clearable
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-12 col-sm-6 h-75px">
        <v-text-field
          v-model="formData.number_of_packages"
          :label="number_of_packages_label"
          type="number"
          min="0"
          clearable
          outlined
          dense
          @change="() => validateMinValue('number_of_packages', 0)"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-6 h-75px" v-if="formData.package_type == '1'">
        <v-text-field
          v-model="formData.total_number_of_boxes"
          label="Total number of boxes"
          type="number"
          min="0"
          clearable
          outlined
          dense
          @change="() => validateMinValue('total_number_of_boxes', 0)"
          :error-messages="total_number_of_boxesErrors"
          @input="$v.formData.total_number_of_boxes.$touch()"
          @blur="$v.formData.total_number_of_boxes.$touch()"
        ></v-text-field>
      </div>

      <div class="col-12 col-sm-6 h-75px">
        <v-text-field
          class
          v-model="formData.weight"
          type="number"
          min="0"
          label="Weight"
          clearable
          outlined
          dense
          @change="() => validateMinValue('weight', 0)"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-6 h-75px">
        <v-text-field
          class
          v-model="formData.volumetric_weight"
          type="number"
          min="0"
          label="Volumetric weight"
          clearable
          outlined
          dense
          @change="() => validateMinValue('volumetric_weight', 0)"
        ></v-text-field>
      </div>

      <CollectionAddress
        ref="collectionAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
        :customer_id="customer_id"
        :getStep2Data="getStep2Data"
      ></CollectionAddress>
    </form>

    <v-main class="text-center mt-1 mb-4 col-12 elevation-0">
      <button
        type="reset"
        class="btn btn-light mx-3 px-5 py-3 ls1"
        @click="resetCreateForm"
      >
        Clear
      </button>
      <button
        type="submit"
        class="btn btn-info px-5 py-3 ls1"
        @click="submitCreateForm"
      >
        Submit
      </button>
      <!--      <v-btn class="mx-1" color="primary" @click="submitCreateForm">-->
      <!--        submit-->
      <!--      </v-btn>-->
      <!--      <v-btn class="mx-1" color="red" dark @click="resetCreateForm">-->
      <!--        clear-->
      <!--      </v-btn>-->
      <button
        type="reset"
        class="btn btn-light mx-3 px-5 py-3 ls1"
        @click="goBack"
      >
        Back
      </button>
    </v-main>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import CollectionAddress from "@/own/components/shipping/pickupOrders/addressComponents/CollectionAddress";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
// import ApiService from "@/core/services/api.service";

export default {
  name: "StockForm",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    CollectionAddress,
    // DestinationAddress,
  },
  props: [
    "setData",
    "actionFuntion",
    "serverData",
    "pageLoader",
    "initData",
    "userId",
    "customer_id",
    "getStep2Data",
  ],
  validations() {
    return {
      formData: {
        collection_date: { required },
        warehouse_id: { required },
        collection_address_id: { required },
        // destination_country_id: { required },
        vehicle_owner_type_id: { required },
        vehicle_tonnage_id: {
          required: requiredIf(function () {
            return this.formData.vehicle_owner_type_id == 2;
          }),
        },
        total_number_of_boxes: {
          required: requiredIf(function () {
            return this.formData.package_type == 1;
          }),
        },
        // destination_city_id: {
        //   required: requiredIf(function () {
        //     return this.formData.vehicle_owner_type_id == 2;
        //   }),
        // },
      },
    };
  },
  data: () => ({
    city_search_text: "",
    menu1: false,
    doneTypingInterval: 700,
    typingTimer: null,
    isLoading: false,
    formData: {
      package_type: null,
      number_of_packages: null,
      collection_date: null,
      total_number_of_boxes: null,
      collection_address_id: null,
      warehouse_id: null,
      weight: null,
      volumetric_weight: null,
      destination_address_type: 1,
      // destination_country_id: null,
      // destination_city_id: null,
      vehicle_owner_type_id: null,
      vehicle_tonnage_id: null,
      order_ids: [],
    },
    collection_addresses: [],
  }),
  methods: {
    // async getCitiesFromServer(search_text) {
    //   this.pageLoader(true);
    //   await ApiService.post("/address/cities/search", {
    //     q: search_text,
    //     country: this.formData.destination_country_id,
    //   })
    //     .then((response) => {
    //       this.serverData.cities = response.data.cities;
    //       this.pageLoader(false);
    //       this.isLoading = false;
    //     })
    //     .catch(() => {
    //       this.pageLoader(false);
    //       this.isLoading = false;
    //     });
    // },
    formatDate(date) {
      return date ? date.split("-").reverse().join("-") : null;
    },
    handleChangeMultiple(val) {
      let seperated = [];
      val.forEach((element) => {
        let subElement = element.trim().split(/(?:,| )+/);
        subElement.forEach((sub) => {
          seperated.push(sub);
        });
      });
      this.formData.order_ids = seperated;
    },
    createNewItem() {
      this.$nextTick(() => {
        this.$refs.collectionAddressComponent.toggleModal();
      });
    },
    goBack() {
      this.actionFuntion("back");
    },
    setDataOfAddress(data, type) {
      this.formData[`${type}`] = data;
    },
    submitCreateForm() {
      this.$v.$touch();
      // const comp1 = this.$refs.collectionAddressComponent.submitData();
      // const comp2 = this.$refs.destinationAddressComponent.submitData();
      if (this.$v.$invalid) {
        return;
      }
      // if (!comp1) {
      //   return;
      // }
      // if (!comp2) {
      //   return;
      // }

      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = {
        ...this.formData,
      };
      data.collection_date = this.formatDate(this.formData.collection_date);
      // data.collection_address_id = this.formData.collection_address_id.index;
      this.setData(data);
      this.actionFuntion();
      // this.resetCreateForm();
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        // try {
        let copy = { ...this.serverData.pickup_order };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.pickup_order).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });

          // if (
          //   this.formData.collection_date &&
          //   this.formData.collection_date != ""
          // ) {
          //   this.formData.collection_date = Date(
          //     ...this.formData.collection_date
          //       .split("-")
          //       .reverse()
          //       .map((n, i) => (i === 1 ? n - 1 : n))
          //   );
          // }
          // this.$nextTick(() => {

          // });
        });
        this.formData.collection_date = this.formatDate(
          this.formData.collection_date
        );
        // console.log(this.formData.collection_date);
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // } catch (e) {
        // console.error(e);
        // this.formData = {
        //   package_type: null,
        //   number_of_packages: null,
        //   collection_date: null,
        // };
        // }
      } else {
        // this.$nextTick(() => {
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // });

        this.formData = {
          package_type: null,
          number_of_packages: null,
          collection_date: null,
          total_number_of_boxes: null,
          collection_address_id: null,
          warehouse_id: null,
          weight: null,
          volumetric_weight: null,
          destination_address_type: 1,
          // destination_country_id: null,
          // destination_city_id: null,
          vehicle_owner_type_id: null,
          vehicle_tonnage_id: null,
          order_ids: [],
        };
        // this.$refs.datetime.clearHandler();
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    collection_dateErrors() {
      return this.handleFormValidation("collection_date", this);
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    // destination_country_idErrors: function () {
    //   return this.handleFormValidation("destination_country_id", this);
    // },
    vehicle_owner_type_idErrors: function () {
      return this.handleFormValidation("vehicle_owner_type_id", this);
    },
    // destination_city_idErrors: function () {
    //   return this.handleFormValidation("destination_city_id", this);
    // },
    vehicle_tonnage_idErrors: function () {
      return this.handleFormValidation("vehicle_tonnage_id", this);
    },
    total_number_of_boxesErrors: function () {
      return this.handleFormValidation("total_number_of_boxes", this);
    },
    collection_address_idErrors: function () {
      return this.handleFormValidation("collection_address_id", this);
    },
    number_of_packages_label() {
      return this.formData.package_type == "2"
        ? "Number of boxes"
        : "Number of pallet";
    },
  },
  // watch: {
  //   city_search_text(val) {
  //     clearTimeout(this.typingTimer);
  //     this.isLoading = true;
  //     if (val && val != "" && [...val].length > 1) {
  //       this.typingTimer = setTimeout(() => {
  //         this.getCitiesFromServer(val.trim());
  //       }, this.doneTypingInterval);
  //     } else {
  //       this.isLoading = false;
  //       return;
  //     }
  //   },
  // },
};
</script>
